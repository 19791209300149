import { render, staticRenderFns } from "./SectionNewsletter.vue?vue&type=template&id=3a706341&scoped=true"
import script from "./SectionNewsletter.vue?vue&type=script&lang=js"
export * from "./SectionNewsletter.vue?vue&type=script&lang=js"
import style0 from "./SectionNewsletter.vue?vue&type=style&index=0&id=3a706341&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a706341",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Form: require('/web/components/form/Form.vue').default})
