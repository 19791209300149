
import { mapState } from "vuex";

export default {
  name: "Newsletter",
  props: ["block", "sidebar"],
  data() {
    return {
      radios: [],
      selectedRadio: 0,
      selectedRadioName: "RCF Nationale",
      stateEventCheckBox: false,
      stateRgpdCheckBox: false,
      showConfirmationPopup: false,
      confirmationMessage: "",
      successState: ""
    };
  },
  components: {
    ConfirmationPopup: () => import("@/components/popups/ConfirmationPopup.vue")
  },
  async fetch() {
    try {
      await this.$store.dispatch("form/getFields", {
        id: "newsletter/elements",
      });
    } catch (e) {
      console.log(e);
    }
  },
  computed: {
    ...mapState({
      radioOptions: (state) => {
        if (!state.form.fields?.list_radios_dialog_insight?.["#options"]) 
          return []
        let options = state.form.fields.list_radios_dialog_insight["#options"];
        options = Object.entries(options).map(([id, name]) => ({id, name}));

        const national = {
          name: "RCF Nationale",
          value: options
            .find((opt) => opt.name.includes('Nationale'))
            ?.id,
        };

        const filterdOptions = options
          .filter(
            (opt) =>
              !opt.name.includes("Nationale") && !opt.name.includes("Aucun(e)")
          )
          .map((opt) => {
            return {
              name: opt.name.replace("Newsletter ", ""),
              value: opt.id,
            };
          });

        return [national, ...filterdOptions];
      },
    }),
  },
  watch: {
    selectedRadioName() {
      this.$nextTick(() => {
        this.resizeSelect();
      });
    },
  },
  methods: {
    async handleSubmit(event) {
      const submitBtn = document.getElementById("submitBtn");
      submitBtn.disabled = true;
      const token = await this.$recaptcha.execute("login");
      const siteverify = await this.$defaultRepository.getByFullUrl(
        this.$config.baseURL + "/recaptcha-siteverify/verify",
        {
          token: token,
        }
      );

      if (typeof siteverify.success !== "undefined" && siteverify.success) {
        try {
          let submission = await this.$store.dispatch("form/submit", {
            form: event.target,
            webform_id: event.target.id,
          })
          if (submission && submission.sid) {
            this.openConfirmationPopup("Votre inscription à la newsletter a bien été enregistrée !")
          } else {
            this.openErrorPopup("Erreur lors de la soumission du formulaire. Veuillez réessayer.")
          }
        } catch (error) {
          console.error("Une erreur s'est produite : ", error)
          this.openErrorPopup("Une erreur s'est produite. Veuillez réessayer.")
        }
      }
      submitBtn.disabled = false;
    },
    handleSelect(event) {
      this.selectedRadio = event.target.value;
      this.selectedRadioName = this.radioOptions.find(
        (radio) => radio.value === event.target.value
      ).name;
    },
    resizeSelect() {
      const selectRadioNameWidth = this.$refs.selectedRadioNameRef.offsetWidth;
      this.$refs.selectRadioRef.style.width = `${
        Number(selectRadioNameWidth) + 40
      }px`;
    },
    handleCheckBox(targetLabel) {
      switch (targetLabel) {
        case 'eventCheckbox':
          this.stateEventCheckBox = !this.stateEventCheckBox;
          break;

        case 'rgpdCheckbox':
          this.stateRgpdCheckBox = !this.stateRgpdCheckBox;
          break;
      }
    },
    openConfirmationPopup(message, success) {
      this.confirmationMessage = message;
      this.showConfirmationPopup = true;
      this.successState = success;
    },
    closeConfirmationPopup() {
      this.showConfirmationPopup = false;
      this.confirmationMessage = "";
    },
    openErrorPopup(message, success) {
      this.confirmationMessage = message;
      this.showConfirmationPopup = true;
      this.successState = success;
    }
  },
  mounted() {
    this.resizeSelect();
  },
};
